/* @font-face {
    font-family: american_typewriter;
    src: url(fonts/AMERICAN_TYPEWRITER_REGULAR.TTF);
} */

 body {
    background-color: #efefef;
    margin: 0;
    padding: 0;
}

.main-wrapper {
    width: 1140px;
    margin: auto;
    padding: 40px 0;
}

.main-wrapper .ting-logo {
    text-align: center;
    margin: 0 0 60px;
}

.logo-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
}

.logo-section .logo-inside {
    width: 30%;
    padding: 40px 0;
}

.logo-section .logo-inside p {
    /* font-family: american_typewriter; */
    font-size: 18px;
    color: #000000;
    margin: 0;
    padding: 20px 0 0;
}

.logo-section .logo-inside a {
    text-decoration: none;
}

.logo-img {
    min-height: 100px;
}
.title-font{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@media screen and (max-width: 1200px) {
    .main-wrapper {
        width: 100%;
    }

}

@media screen and (max-width: 767px) {

    .logo-section .logo-inside {
        width: 100%;
    }

    .logo-img {
        min-height: auto;
    }
}







